// import request from '../utils/request.js'
import request from '../utils/request'

// 获取用户数据
export function getUserList(data) {
  return request({
    url: '/api/system/user/listall',
    method: 'post',
    data
  })
}
// 根据 角色ID 读取 菜单列表 POST
// /api/system/role/list?id=1 
export function getRoleList(data) {
  return request({
    url: '/api/system/role/list',
    method: 'post',
    data
  })
}
// 系部的接口
export function getSystemList(data) {
  return request({
    url: '/api/sysdepartment/getDepartList',
    method: 'post',
    data
  })
}
// 根据token 获取到 当前用户信息（包括角色ID）
export function getCurrUserItem(data) {
  return request({
    url: '/api/account/current',
    method: 'post',
    data
  })
}
// 检测 角色 有没有 编辑权限
export function chechRoleEditPower(roleID) {
  if (roleID < 1 || roleID > 6)
    return false;
  else
    return true;
}
// 新增 权限
export function checkRoleAddPowerAdmin(roleID) {
  // 1	管理员 2	班主任 3	系主任 4	校负责领导 5	学生会考评员 6	老师
  // 7 学生
  return roleID != 5 && roleID != 7;
}
// 编辑 权限
export function checkRoleEditPowerAdmin(roleID) {
  // 1	管理员 
  return roleID == 1;
}
// 导入导出 权限
export function checkRoleExportPowerAdmin(roleID) {
  // 1	管理员
  return roleID == 1;
}

// 获取教师管理下新增学生的班级的接口
export function getCnameList(data) {
  return request({
    url: '/api/sysclass/list',
    method: 'post',
    data
  })
}
// 获取教师管理下新增学生的班级的接口
export function getClassCardList(data) {
  return request({
    url: '/api/sysclass/page',
    method: 'post',
    data
  })
}
// 上传图片
export function transPhoto(data) {
  return request({
    url: '/api/sysassessment/uploadAvator',
    method: 'post',
    data
  })
}
// 添加学生
export function transAddInfo(data) {
  return request({
    url: '/api/system/user/add',
    method: 'post',
    data
  })
}
// 教师管理下面的删除
export function deleteInfo(query) {
  return request({
    url: '/api/system/user/remove',
    method: 'get',
    params: query
  })
}
// 课节管理
export function getSysslassdayList(data) {
  return request({
    url: '/api/sysslassday/list',
    method: 'post',
    data
  })
}
// 课节管理
export function addSysslassdayList(data) {
  return request({
    url: '/api/sysslassday/add',
    method: 'post',
    data
  })
}
// 课节管理修改
export function updateSysslassday(data) {
  return request({
    url: '/api/sysslassday/update',
    method: 'post',
    data
  })
}

// 添加学生指标的信息
export function addStuScoringDetails(data) {
  return request({
    url: '/api/sysstudentindex/add',
    method: 'post',
    data
  })
}
// 拼接字符串的
export function typeG(val) {
  return request({
    url: '/api/system/user/remove/' + val,
    method: 'post',
  })
}
// 编辑 指标
export function updateStudentIndex(data) {
  return request({
    url: '/api/sysstudentindex/update',
    method: 'post',
    data
  })
}
// 删除 指标
export function deleteStuScoringDetails(id) {
  return request({
    url: '/api/sysstudentindex/remove/' + id,
    method: 'post',
  })
}
// 修改学生管理的信息
export function updateStuArgument(id, data) {
  return request({
    url: '/api/system/user/update/' + id,
    method: 'post',
    data
  })
}
// 删除学生管理的信息
export function deleteStuArgument(id) {
  return request({
    url: '/api/system/user/remove/' + id,
    method: 'post',
  })
}

// 卡片管理下的班级信息
export function getCardArgumentDetail(data) {
  return request({
    url: '/api/sysclass/list',
    method: 'post',
    data
  })
}

// 获取 考核记录 列表
export function getAssessmentRecordList(data) {
  return request({
    url: '/api/sysassessment/getPageListAssessment',
    method: 'post',
    data
  })
}
// 导出 考核记录
export function exportAssessmentRecordList(data, type) {
  return request({
    url: '/api/sysassessment/exportData' + type,
    method: 'post',
    data
  })
}
// 新增 单条 考核记录
export function insertAssessmentDetail(data) {
  return request({
    url: '/api/sysassessment/insert',
    method: 'post',
    data
  })
}
// 编辑 单条 考核记录
export function updateAssessmentDetail(data) {
  return request({
    url: '/api/sysassessment/updateAssessment',
    method: 'post',
    data
  })
}

// 考核记录管理 评分类别 列表
export function getScoreCategoryList(data) {
  return request({
    url: '/api/sysscoreclass/list',
    method: 'post',
    data // starts: this.starts, // 0学生 1班级
  })
}
// 读取 指标 列表 (评分明细列表)
export function getScoreContentList(data) {
  return request({
    url: '/api/sysstudentindex/selectall',
    method: 'post',
    data
  })
}
// 获取 学生指标下面的 评分明细 下拉框
export function stuScoringDetails(data) {
  return request({
    url: '/api/sysstudentindex/findByscid/1',
    method: 'post',
    data
  })
}

// 记录管理 课节时间
export function getAssessmentTimeList(data) {
  return request({
    url: '/api/sysslassday/list',
    method: 'post',
    data
  })
}
// 记录管理 撤销
export function backAssessment(id) {
  return request({
    url: '/api/sysassessment/backByID?id=' + id,
    method: 'post',
  })
}
// 记录管理删除
export function deleteAssessment(id) {
  return request({
    url: '/api/sysassessment/removeByID?id=' + id,
    method: 'post',
  })
}

// 算分接口
export function addScore(data) {
  return request({
    url: '/api/sysassessment/selectByscore',
    method: 'post',
    data
  })
}

// 全校数据 日
export function getdataday(data) {
  return request({
    url: '/api/data/findByDay',
    method: 'post',
    data
  })
}

// 全校数据周
export function getdataweek(data) {
  return request({
    url: '/api/data/findByWeek',
    method: 'post',
    data
  })
}
// 全校数据 月
export function getdatamonth(data) {
  return request({
    url: '	/api/data/findByMonth',
    method: 'post',
    data
  })
}

// 院系数据 院系下查询班级
export function getselectID(id) {
  return request({
    url: "/api/sysclass/selectBydepid/" + id,
    method: 'post'

  })
}

// 院系数据 日
export function getpartmentday(data) {
  return request({
    url: '/api/data/findDepByDay',
    method: 'post',
    data
  })
}
// 院系数据 周
export function getpartmentweek(data) {
  return request({
    url: '/api/data/findDepByWeek',
    method: 'post',
    data
  })
}
// 院系数据 月
export function getpartmentmonth(data) {
  return request({
    url: '/api/data/findDepByMonth',
    method: 'post',
    data
  })
}

// 班级下所有学生
export function getstudentID(id) {
  return request({
    url: '/api/system/user/findBycid/' + id,
    method: 'post',
  })
}

// 班级数据 日 折线图数据
export function classdayCid(data) {
  return request({
    url: '/api/data/findClassByday',
    method: 'post',
    data
  })
}
// 班级数据 周
export function classweekCid(data) {
  return request({
    url: '/api/data/findClassByWeek',
    method: 'post',
    data
  })
}
// 班级数据 月
export function classmonthCid(data) {
  return request({
    url: '/api/data/findClassByMonth',
    method: 'post',
    data
  })
}

// 学生雷达图数据 
export function studentRadar(data) {
  return request({
    url: '/api/sysassessment/findBystudent',
    method: 'post',
    data
  })
}

// 课节设置 删除
export function handdelete(id) {
  return request({
    url: '/api/sysslassday/remove/' + id,
    method: 'post',
  })
}

// 全校 班级指标 饼图
export function piechartSchool(data) {
  let url = "/api/sysassessment/findBycountsca";
  let joinChar = "?";
  if (data.starttime != null) {
    url += joinChar + 'starttime=' + data.starttime;
    joinChar = '&';
  } else {
    url += joinChar + 'starttime=';
    joinChar = '&';
  }
  if (data.endtime != null) {
    url += joinChar + 'endtime=' + data.endtime;
    joinChar = '&';
  } else {
    url += joinChar + 'endtime=';
    joinChar = '&';
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}
// 全校 学生指标 饼图
export function studentChartAll(data) {
  return request({
    url: '/api/pie/chart/allSchool?starttime=' + data.starttime + '&endtime=' + data.endtime,
    method: 'post',
    data
  })
}

// 班级饼图数据
export function piechartclass(data) {
  let url = "/api/sysassessment/findBycountclass";
  let joinChar = "?";
  if (data.starttime != null) {
    url += joinChar + 'starttime=' + data.starttime;
    joinChar = '&';
  } else {
    url += joinChar + 'starttime=';
    joinChar = '&';
  }
  if (data.endtime != null) {
    url += joinChar + 'endtime=' + data.endtime;
    joinChar = '&';
  } else {
    url += joinChar + 'endtime=';
    joinChar = '&';
  }
  if (data.depId != null) {
    url += joinChar + 'depId=' + data.depId;
    joinChar = '&';
  } else {
    url += joinChar + 'depId=-1';
    joinChar = '&';
  }
  if (data.classId != null) {
    url += joinChar + 'classId=' + data.classId;
    joinChar = '&';
  } else {
    url += joinChar + 'classId=-1';
    joinChar = '&';
  }
  if (undefined != data.sourceType && data.sourceType != null) {
    url += joinChar + 'sourceType=' + data.sourceType;
    joinChar = '&';
  } else {
    url += joinChar + 'sourceType=';
    joinChar = '&';
  }
  if (undefined != data.starts && data.starts != null) {
    url += joinChar + 'starts=' + data.starts;
    joinChar = '&';
  } else {
    url += joinChar + 'starts=0';
    joinChar = '&';
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 院系学生指标
export function depOrClass(data) {
  let url = "/api/pie/chart/depOrClass";
  let joinChar = "?";
  if (data.starttime != null) {
    url += joinChar + 'starttime=' + data.starttime;
    joinChar = '&';
  } else {
    url += joinChar + 'starttime=';
    joinChar = '&';
  }
  if (data.endtime != null) {
    url += joinChar + 'endtime=' + data.endtime;
    joinChar = '&';
  } else {
    url += joinChar + 'endtime=';
    joinChar = '&';
  }
  if (data.depId != null) {
    url += joinChar + 'depId=' + data.depId;
    joinChar = '&';
  } else {
    url += joinChar + 'depId=-1';
    joinChar = '&';
  }
  if (data.classId != null) {
    url += joinChar + 'classId=' + data.classId;
    joinChar = '&';
  } else {
    url += joinChar + 'classId=-1';
    joinChar = '&';
  }
  if (undefined != data.sourceType && data.sourceType != null) {
    url += joinChar + 'sourceType=' + data.sourceType;
    joinChar = '&';
  } else {
    url += joinChar + 'sourceType=';
    joinChar = '&';
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 学生 日 周 月 
export function getstudentDay(data) {
  return request({
    url: '/api/sysassessment/findBystudentday',
    method: 'post',
    data
  })
}
export function getstudentWeek(data) {
  return request({
    url: '/api/sysassessment/findBystudentweek',
    method: 'post',
    data
  })
}
export function getstudentMonth(data) {
  return request({
    url: '/api/sysassessment/findBystudentmonth',
    method: 'post',
    data
  })
}

// 学生饼图
export function groupByPieChart(data) {
  return request({
    url: '/api/sysassessment/groupByPieChart',
    method: 'post',
    data
  })
}

// 添加院系
export function addDepartment(data) {
  return request({
    url: '/api/sysdepartment/add',
    method: 'post',
    data
  })
}
// 删除院系
export function removeDepartment(id) {
  return request({
    url: '/api/sysdepartment/remove/' + id,
    method: 'post',
  })
}
// 修改
export function updataDepartment(data) {
  return request({
    url: '/api/sysdepartment/update',
    method: 'post',
    data
  })
}
// 消息管理 获取 预警消息  列表
export function searchNews(data) {
  return request({
    url: '/api/sysnews/page',
    method: 'post',
    data
  })
}
// 消息管理 消息详情
export function findById(id) {
  return request({
    url: '/api/sysnews/findById/' + id,
    method: 'post'
  })
}
// 消息管理 添加消息列表
export function addNews(data) {
  return request({
    url: '/api/sysnews/insert',
    method: 'post',
    data
  })
}
// 消息管理 更新消息列表
export function updateNews(data) {
  return request({
    url: '/api/sysnews/update',
    method: 'post',
    data
  })
}
// 消息管理 删除消息列表
export function removeNews(id) {
  return request({
    url: '/api/sysnews/remove/' + id,
    method: 'post'
  })
}

// 记录管理 批量删除
export function batchDeleteAssessment(query) {
  return request({
    url: '/api/sysassessment/removeByIDS/',
    method: 'get',
    params: query
  })
}

// 修改密码
export function editPassword(data) {
  return request({
    url: '/api/system/user/changePwd',
    method: 'post',
    data
  })
}

// 全部的 菜单定义 列表（可多级）
export function getAllMenuPageList(data) {
  return request({
    url: '/api/system/resource/list',
    method: 'post',
    data
  })
}

// updata 修改
export function updataRolePagePermision(data) {
  return request({
    url: '/api/system/role/update',
    method: 'post',
    data
  })
}
// 学生姓名查询
export function selectByUser(data) {
  return request({
    url: '/api/system/user/selectByUser',
    method: 'post',
    data
  })
}

// 全校词云
export function groupByAll(data) {
  return request({
    url: '/api/sysassessment/groupByAll',
    method: 'post',
    data
  })
}
// 班级词云
export function groupByClassId(data) {
  return request({
    url: '/api/sysassessment/groupByClassId',
    method: 'post',
    data
  })
}
// 院系词云
export function groupByDepId(data) {
  return request({
    url: '/api/sysassessment/groupByDepId',
    method: 'post',
    data
  })
}
// 学生词云
export function groupByStudent(data) {
  return request({
    url: '/api/sysassessment/groupByStudent',
    method: 'post',
    data
  })
}

// 查询学生指标的信息
export function searchStudent(id) {
  return request({
    url: '/api/system/user/get/id/' + id,
    method: 'post',
  })
}

// 组织架构
export function getDeptClassUser(data) {
  return request({
    url: '/api/sysnews/getDeptClassUser',
    method: 'post',
    data
  })
}
// 搜索学生
export function selectBySearchName(data) {
  return request({
    url: 'api/system/user/selectBySearchName',
    method: 'post',
    data
  })
}

/**
 * 查询宿舍楼列表信息接口
 * @param {*} query 请求参数
 */
export function selectDormitoryList(data) {
  return request({
    url: '/api/system/user/selectDormitoryList',
    method: 'post',
    data
  })
}

// 新版 院系获取班级、学生饼图数据
export function getFacultyStatistics(data) {
  let url = "/api/sysassessment/getFacultyStatistics";
  let joinChar = "?";
  if (data.starttime != null) {
    url += joinChar + 'starttime=' + data.starttime;
    joinChar = '&';
  } else {
    url += joinChar + 'starttime=';
    joinChar = '&';
  }
  if (data.endtime != null) {
    url += joinChar + 'endtime=' + data.endtime;
    joinChar = '&';
  } else {
    url += joinChar + 'endtime=';
    joinChar = '&';
  }
  if (data.depId != null) {
    url += joinChar + 'depId=' + data.depId;
    joinChar = '&';
  } else {
    url += joinChar + 'depId=-1';
    joinChar = '&';
  }
  if (data.classId != null) {
    url += joinChar + 'classId=' + data.classId;
    joinChar = '&';
  } else {
    url += joinChar + 'classId=-1';
    joinChar = '&';
  }
  if (undefined != data.sourceType && data.sourceType != null) {
    url += joinChar + 'sourceType=' + data.sourceType;
    joinChar = '&';
  } else {
    url += joinChar + 'sourceType=';
    joinChar = '&';
  }
  if (undefined != data.starts && data.starts != null) {
    url += joinChar + 'starts=' + data.starts;
    joinChar = '&';
  } else {
    url += joinChar + 'starts=0';
    joinChar = '&';
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 新的 全校 获取班级指标及学生指标 饼图
export function getWholeSchoolDstaList(data) {
  let url = "/api/sysassessment/getWholeSchoolDstaList";
  let joinChar = "?";
  if (data.starttime != null) {
    url += joinChar + 'starttime=' + data.starttime;
    joinChar = '&';
  } else {
    url += joinChar + 'starttime=';
    joinChar = '&';
  }
  if (data.endtime != null) {
    url += joinChar + 'endtime=' + data.endtime;
    joinChar = '&';
  } else {
    url += joinChar + 'endtime=';
    joinChar = '&';
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

// 导出 考核记录
export function exportSysData(data) {
  return request({
    url: '/api/sysassessment/exportSysData',
    method: 'post',
    data
  })
}

// 获取用户名片列表信息接口
export function getUserBusinessCardList(data) {
  return request({
    url: '/api/system/user/getUserBusinessCardList',
    method: 'post',
    data
  })
}

// 获取用户名片列表信息接口
export function getClassBusinessCardList(data) {
  return request({
    url: '/api/sysclass/getClassBusinessCardList',
    method: 'post',
    data
  })
}

/**
 * 根据查询条件分页获取评分类别接口
 * 
 * @param {*} data 参数信息
 * @returns 
 */
export function getSysScoreClassPage(data) {
  return request({
    url: '/api/sysscoreclass/selectSysScoreClassPage',
    method: 'post',
    data
  })
}

/**
 * 新增评分类别维护接口
 * 
 * @param {*} data 请求参数
 * @returns 
 */
export function addSysScoreClass(data) {
  return request({
    url: '/api/sysscoreclass/add',
    method: 'post',
    data
  })
}

/**
 * 更新评分类别维护接口
 * 
 * @param {*} data 请求参数
 * @returns 
 */
export function updateSysScoreClass(data) {
  return request({
    url: '/api/sysscoreclass/update',
    method: 'post',
    data
  })
}

// 删除评分类别维护接口
export function removeSysScoreClass(id) {
  return request({
    url: '/api/sysscoreclass/remove/' + id,
    method: 'post'
  })
}